import React, { useState, useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CCol,
  CRow,
  CButton,
} from "@coreui/react";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsExclamationCircle } from "react-icons/bs";
import { CiCircleCheck } from "react-icons/ci";
import GetTestStatus from "../GetTestStatus";

// Define the types for the props
interface LatestDiagnostic {
  old: boolean;
  dated: string;
}

type HandleYesClick = () => void;

interface DiagnosticProps {
  latestDiagnostic: LatestDiagnostic;
  handleYesClick: HandleYesClick;
}

const Diagnostic: React.FC<DiagnosticProps> = ({
  latestDiagnostic,
  handleYesClick,
}) => {
  const [buttonColor, setButtonColor] = useState("#D8D8D8");
  const [textColor, setTextColor] = useState("#000000");

  useEffect(() => {
    // Move helper functions inside useEffect
    const getButtonColor = () => {
      if (latestDiagnostic?.dated === "--/--/--" && latestDiagnostic?.old === null) {
        return "#D8D8D8";
      } else if (latestDiagnostic?.dated === "--/--/--" && latestDiagnostic?.old === true) {
        return "#FF0000";
      }
      return latestDiagnostic?.old ? "#FF0000" : "#1488f5";
    };

    const getTextColor = () => {
      if (latestDiagnostic?.dated === "--/--/--" && latestDiagnostic?.old === null) {
        return "#000000";
      }
      return "#FFFFFF";
    };

    const newColor = getButtonColor();
    const newTextColor = getTextColor();
    setButtonColor(newColor);
    setTextColor(newTextColor);
  }, [latestDiagnostic]);

  // Helper function to determine button text
  const getButtonText = () => {
    if (latestDiagnostic?.dated === "--/--/--") {
      return "Take a diagnostic";
    }
    return !latestDiagnostic.old ? "Completed" : "Take a diagnostic";
  };


  return (
    <CCard style={{ backgroundColor: "#DAEEF5" }}>
      <CCardBody>
        <CCardTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <BiSearchAlt2
              style={{
                color: "#1488f5",
                height: "150px",
              }}
              size="100"
            />
          </div>
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Diagnostic
          </h3>
        </CCardTitle>

        <CCardText>
          <CRow
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CCol
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "2.8rem",
              }}
            >
              <CRow>
                <small style={{ color: "grey" }}>Status</small>
              </CRow>

              <CRow>
                <small
                  style={{
                    fontFamily: "Expansiva, sans-serif",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <GetTestStatus test={latestDiagnostic?.old === false} />
                  {latestDiagnostic?.old === false ? "Active" : "Inactive"}
                </small>
              </CRow>
            </CCol>
            <CCol
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "2.8rem",
              }}
            >
              <CRow>
                <small style={{ color: "grey" }}>Previous Date</small>
              </CRow>

              <CRow>
                <small
                  style={{
                    fontFamily: "Expansiva, sans-serif",
                    fontSize: "0.7rem",
                  }}
                >
                  {latestDiagnostic?.dated.replace(/-/g, ".").slice(0, 10)}
                </small>
              </CRow>
            </CCol>
          </CRow>
        </CCardText>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CButton
            className="centeralized no-margin"
            style={{
              backgroundColor: buttonColor,
              color: textColor,
            }}
            color="primary"
            onClick={handleYesClick}
            title={latestDiagnostic?.old ? "Click to run system diagnostic" : ""}
          >
            {latestDiagnostic?.old ? (
              <BsExclamationCircle style={{ marginRight: "5px" }} size="20" />
            ) : (
              <CiCircleCheck
                data-testid={"ci-circle-check-icon"}
                style={{ marginRight: "5px" }}
                size="20"
              />
            )}
            {getButtonText()}
          </CButton>
        </div>
      </CCardBody>
    </CCard>
  );
};

export default Diagnostic;
