import React, { useState, useEffect } from "react";
import GetTestStatus from "../GetTestStatus";
import { CiCircleCheck } from "react-icons/ci";
import { MdOutlineSpeed } from "react-icons/md";
import { BsExclamationCircle } from "react-icons/bs";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CCol,
  CRow,
  CButton,
} from "@coreui/react";

interface LatestUnitCalibration {
	old: boolean;
	dated: string;
}

type HandleUnitCalibration = () => Promise<void>;

interface CalibrationProps {
	latestUnitCalibration: LatestUnitCalibration;
	handleUnitCalibration: HandleUnitCalibration;
}

const Calibration: React.FC<CalibrationProps> = ({
  latestUnitCalibration,
  handleUnitCalibration,
}) => {
  const [buttonColor, setButtonColor] = useState("#D8D8D8");
  const [textColor, setTextColor] = useState("#000000");

  useEffect(() => {
    // Move helper functions inside useEffect to avoid dependency issues
    const getButtonColor = () => {
      if (latestUnitCalibration?.dated === "--/--/--" && latestUnitCalibration?.old === null) {
        return "#D8D8D8";
      } else if (latestUnitCalibration?.dated === "--/--/--" && latestUnitCalibration?.old === true) {
        return "#FF0000";
      }
      return latestUnitCalibration?.old ? "#FF0000" : "#1488f5";
    };

    const getTextColor = () => {
      if (latestUnitCalibration?.dated === "--/--/--" && latestUnitCalibration?.old === null) {
        return "#000000";
      }
      return "#FFFFFF";
    };

    const newColor = getButtonColor();
    const newTextColor = getTextColor();
    setButtonColor(newColor);
    setTextColor(newTextColor);
  }, [latestUnitCalibration]);

  // Helper function to determine button text
  const getButtonText = () => {
    if (latestUnitCalibration?.dated === "--/--/--") {
      return "Calibrate Unit";
    }
    return !latestUnitCalibration.old ? "Completed" : "Calibrate Unit";
  };

  return (
    <CCard style={{ backgroundColor: "#DAEEF5" }}>
      <CCardBody>
        <CCardTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <MdOutlineSpeed
              style={{
                color: "#1488f5",
                height: "150px",
              }}
              size="100"
            />
          </div>
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Calibration
          </h3>
        </CCardTitle>

        <CCardText>
          <CRow
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CCol
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "2.8rem",
              }}
            >
              <CRow>
                <small style={{ color: "grey" }}>Status</small>
              </CRow>

              <CRow>
                <small
                  style={{
                    fontFamily: "Expansiva, sans-serif",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <GetTestStatus test={latestUnitCalibration?.old === false} />
                  {latestUnitCalibration?.old === false ? "Active" : "Inactive"}
                </small>
              </CRow>
            </CCol>
            <CCol
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "2.8rem",
              }}
            >
              <CRow>
                <small style={{ color: "grey" }}>Previous Date</small>
              </CRow>

              <CRow>
                <small
                  style={{
                    fontFamily: "Expansiva, sans-serif",
                    fontSize: "0.7rem",
                  }}
                >
                  {latestUnitCalibration?.dated.replace(/-/g, ".").slice(0, 10)}
                </small>
              </CRow>
            </CCol>
          </CRow>
        </CCardText>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CButton
            className="centeralized no-margin"
            style={{
              backgroundColor: buttonColor,
              color: textColor,
            }}
            color="primary"
            onClick={handleUnitCalibration}
            title={latestUnitCalibration?.old ? "Click to run now or wait for the scheduled time" : ""}
          >
            {latestUnitCalibration?.dated === "--/--/--" ? (
              <BsExclamationCircle style={{ marginRight: "5px" }} size="20" />
            ) : !latestUnitCalibration?.old ? (
              <CiCircleCheck
                data-testid={"ci-circle-check-icon"}
                style={{ marginRight: "5px" }}
                size="20"
              />
            ) : (
              <BsExclamationCircle style={{ marginRight: "5px" }} size="20" />
            )}
            {getButtonText()}
          </CButton>
        </div>
      </CCardBody>
    </CCard>
  );
};

export default Calibration;
