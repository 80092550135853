import { toast } from "react-toastify";
import axios from 'axios';
import ScanResults from "./ScanResults";
import ScheduleAutomationModal from "./ScheduleAutomationModal";
import "../../../assets/css/common.css";
import Diagnostic from "./Cards/Diagnostic";
import Calibration from "./Cards/Calibration";
import { CustomDialog } from "react-st-modal";
import { useNavigate } from "react-router-dom";
import SolventReference from "./Cards/SolventReference";
import React, {useState, useEffect, useCallback, useRef} from "react";
// import { getDevices } from "../../../views/shared/devices";
import SelectYourHardware from "./Cards/SelectYourHardware";
import { CButton, CCol, CContainer, CRow, CFormSwitch, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from "@coreui/react";
import jwtInterceoptor from "../../../views/shared/jwtInterceptor";
import { setCookieItemWithExpiry, isMoreThanOneDay } from "../../../views/shared/utils";

declare global {
	interface Navigator {
		wakeLock: WakeLock;
	}

	interface WakeLock {
		request(type: "screen"): Promise<WakeLockSentinel>;
	}

	interface WakeLockSentinel {
		release(): Promise<void>;
	}
}

type StateObjectTypes = {
	dated: string;
	old: boolean;
};

type FailedComponent = 'Calibration' | 'Diagnostic' | 'Solvent Reference';

const initializeStateWithLocalStorageDataAndCheckExpiryDate = (key: string): object => {
	const defaultState = { old: null, dated: "--/--/--" };
	const storedData = localStorage.getItem(key);
	if (storedData) {
		const parsedData = JSON.parse(storedData);
		return { ...parsedData, old: isMoreThanOneDay(parsedData?.dated) };
	}
	return defaultState;
};

const SystemControlCenter = () => {

	const navigate = useNavigate();
	const [automationTime, setAutomationTime] = useState<string | null>(null);
	const [automationInterval, setAutomationInterval] = useState<number | null>(null);
  const [nextScheduledTime, setNextScheduledTime] = useState<string | null>(null);
  const [lastCompletedWorkflow, setLastCompletedWorkflow] = useState<string | null>(null);
	const [latestDiagnostic, setLatestDiagnostic] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestDiagnostic") as StateObjectTypes);
	const [latestUnitCalibration, setLatestUnitCalibration] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestUnitCalibration") as StateObjectTypes);
	const [latestSolventReference, setLatestSolventReference] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestSolventReference") as StateObjectTypes);
  const [showModal, setShowModal] = useState(false);
	const [isExecutingCommands, setIsExecutingCommands] = useState(false);
	const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);
	const [connections, setConnection] = useState([]);
	const [selectedConnection, setSelectedConnection] = useState(() => {
	const storedConnection = localStorage.getItem("selectedHardware");
		return storedConnection ? JSON.parse(storedConnection) : null;
	});
	const [deviceStatus, setDeviceStatus] = useState(false);
  const [diagnosticSuccess, setDiagnosticSuccess] = useState(() =>
    localStorage.getItem('systemHealth') === 'true' || false
  );
  const [calibrationSuccess, setCalibrationSuccess] = useState(() =>
    localStorage.getItem('systemHealth') === 'true' || false
  );
  const [solventSuccess, setSolventSuccess] = useState(() =>
    localStorage.getItem('systemHealth') === 'true' || false
  );

  // Update errorModal state type
  const [errorModal, setErrorModal] = useState({
    visible: false,
    title: '',
    message: '',
    failedComponent: '' as FailedComponent,
    retryCount: 0
  });

  // Update retryCountRef to match FailedComponent type
  const retryCountRef = useRef({
    'Calibration': 0,
    'Diagnostic': 0,
    'Solvent Reference': 0
  });

  const ErrorModal = () => (
    <CModal
      alignment="center"
      visible={errorModal.visible}
      onClose={() => setErrorModal(prev => ({ ...prev, visible: false }))}
    >
      <CModalHeader>
        <CModalTitle>{errorModal.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {errorModal.message}
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => setErrorModal(prev => ({ ...prev, visible: false }))}
        >
          Close
        </CButton>
        {retryCountRef.current[errorModal.failedComponent as FailedComponent] < 2 && (
          <CButton
            color="primary"
            onClick={() => {
              setErrorModal(prev => ({ ...prev, visible: false }));
              if (errorModal.failedComponent === 'Calibration') handleUnitCalibration();
              if (errorModal.failedComponent === 'Diagnostic') fetchDataCopy();
              if (errorModal.failedComponent === 'Solvent Reference') handleScanHardwareCopy();
            }}
          >
            Retry {errorModal.failedComponent}
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );

  const notifyAutomationServer = async (machineData: {
    machine_name: string | undefined;
    device_type: string;
    command: string;
  }) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_POWER_AUTOMATION_API_URL}`,
        machineData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      console.error('Failed to notify automation server:', error);
    }
  };

  // Function to handle hardware scanning
  const handleScanHardware = async () => {
    let toastId = null;
    try {
      const shouldTakeNewSolventReference = await CustomDialog(
        <ScanResults
          question={"Is the pipetrain CLEAN and FULL of solvent?"}
          yesOption={"Yes, take a new Solvent Reference"}
          centerYesButton={true}
          noOption={null}
        />,
        {
          title: "Scan",
        }
      );

      if (shouldTakeNewSolventReference) {
        toastId = toast.loading("Taking a New Solvent Reference...");

        // Notification with retry count
        await notifyAutomationServer({
          machine_name: selectedConnection?.machine_name,
          device_type: 'System Control Center',
          command: retryCountRef.current['Solvent Reference'] > 0
            ? `Reference started (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
            : 'Reference started'
        });

        const response = await jwtInterceoptor.post(
          `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=scan_hardware&scan_type=solvent`
        );

        // Success notification
        await notifyAutomationServer({
          machine_name: selectedConnection?.machine_name,
          device_type: 'System Control Center',
          command: retryCountRef.current['Solvent Reference'] > 0
            ? `Completed Successful Reference (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
            : 'Completed Successful Reference'
        });

        // Reset retry count on success
        retryCountRef.current['Solvent Reference'] = 0;

        let latestSolventReference = JSON.stringify({
          ...response.data,
          dated: new Date(),
        });
        localStorage.setItem("latestSolventReference", latestSolventReference);
        setLatestSolventReference({ old: false, dated: new Date() + "" });

        setCookieItemWithExpiry("solvent_scan_id", response.data.scan_id);

        toast.update(toastId, {
          render: "Successfully took a new Solvent Reference",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      }
    } catch (error) {
      retryCountRef.current['Solvent Reference']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 1
          ? `Failed Reference (Attempt ${retryCountRef.current['Solvent Reference']})`
          : 'Failed Reference'
      });

      localStorage.removeItem("latestSolventReference");
      setLatestSolventReference({ old: true, dated: "--/--/--" });
      setErrorModal({
        visible: true,
        title: 'Solvent Reference Failed',
        message: retryCountRef.current['Solvent Reference'] >= 2
          ? 'Multiple Solvent Reference attempts failed. Please contact support to resolve the issue.'
          : 'Solvent Reference failed. Please ensure the system is properly connected and try again.',
        failedComponent: 'Solvent Reference',
        retryCount: retryCountRef.current['Solvent Reference']
      });
      if (toastId) {
        toast.update(toastId, {
          render: "Error taking a new Solvent Reference",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      }
    }
  };

  const handleScanHardwareCopy = useCallback(async () => {
    let toastId = null;
    try {
      toastId = toast.loading("Taking a New Solvent Reference...");

      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 0
          ? `Reference started (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
          : 'Reference started'
      });

      const response = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=scan_hardware&scan_type=solvent`
      );

      // Success notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 0
          ? `Completed Successful Reference (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
          : 'Completed Successful Reference'
      });

      // Reset retry count on success
      retryCountRef.current['Solvent Reference'] = 0;

      let latestSolventReference = JSON.stringify({
        ...response.data,
        dated: new Date(),
      });
      localStorage.setItem("latestSolventReference", latestSolventReference);
      setLatestSolventReference({ old: false, dated: new Date() + "" });
      setSolventSuccess(true);
      setCookieItemWithExpiry("solvent_scan_id", response.data.scan_id);

      toast.update(toastId, {
        render: "Successfully took a new Solvent Reference",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (error) {
      retryCountRef.current['Solvent Reference']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 1
          ? `Failed Reference (Attempt ${retryCountRef.current['Solvent Reference']})`
          : 'Failed Reference'
      });

      localStorage.removeItem("latestSolventReference");
      setLatestSolventReference({ old: true, dated: "--/--/--" });
      setSolventSuccess(false);
      setErrorModal({
        visible: true,
        title: 'Solvent Reference Failed',
        message: retryCountRef.current['Solvent Reference'] >= 2
          ? 'Multiple Solvent Reference attempts failed. Please contact support to resolve the issue.'
          : 'Solvent Reference failed. Please ensure the system is properly connected and try again.',
        failedComponent: 'Solvent Reference',
        retryCount: retryCountRef.current['Solvent Reference']
      });
      if (toastId) {
        toast.update(toastId, {
          render: "Error taking a new Solvent Reference",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      }
    }
  }, [selectedConnection]);

  const fetchData = async () => {
    const failureShown = localStorage.getItem("failureShown") === "true";

    const html = "Is the pipetrain CLEAR of solvent and sample?";
    const result = await CustomDialog(
      <ScanResults
        yesOption={"Yes, run a new Diagnostic Scan."}
        noOption={null}
        centerYesButton={true}
        question={html}
      />,
      {
        title: "Scan",
      }
    );
    if (!result) {
      return;
    }

    let toastId = toast.loading("Running System Diagnostic...");
    try {
      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 0
          ? `Diagnostic started (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
          : 'Diagnostic started'
      });

      const lampResponse = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=diagnostic`,
        "diagnostic_type=lamp",
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      if (lampResponse.status === 201) {
        const failedTests = Object.keys(lampResponse.data).filter(
          (lampKey) => lampResponse.data[lampKey].test_result === "Fail"
        );

        if (!failureShown) {
          failedTests.push("dummy_failure");
          localStorage.setItem("failureShown", "true");
        }

        if (failedTests.length > 0) {
          // Failure notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 1
              ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
              : 'Failed Diagnostic'
          });
          toast.update(toastId, {
            render: "Please Clean the Flow Cell",
            type: "error",
            isLoading: false,
            autoClose: 6000,
          });
        } else {
          // Success notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 0
              ? `Completed Successful Diagnostic (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
              : 'Completed Successful Diagnostic'
          });

          // Reset retry count on success
          retryCountRef.current['Diagnostic'] = 0;

          let latestDiagnostic = JSON.stringify({ dated: new Date() });
          localStorage.setItem("latestDiagnostic", latestDiagnostic);
          setLatestDiagnostic({ old: false, dated: new Date() + "" });
          toast.update(toastId, {
            render: "Diagnostics Ran Successfully",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        }
      }
    } catch (error) {
      retryCountRef.current['Diagnostic']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 1
          ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
          : 'Failed Diagnostic'
      });

      console.error("Error fetching data:", error);
      setLatestDiagnostic({ old: true, dated: "--/--/--" });
      setErrorModal({
        visible: true,
        title: 'Diagnostics Failed',
        message: retryCountRef.current['Diagnostic'] >= 2
          ? 'Please contact support to resolve the issue.'
          : 'Please Clean the Flow Cell',
        failedComponent: 'Diagnostic',
        retryCount: retryCountRef.current['Diagnostic']
      });
      toast.update(toastId, {
        render: "Error Running the Diagnostics",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  const fetchDataCopy = useCallback(async () => {
    let toastId = toast.loading("Running System Diagnostic...");
    try {
      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 0
          ? `Diagnostic started (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
          : 'Diagnostic started'
      });

      const lampResponse = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=diagnostic`,
        "diagnostic_type=lamp",
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      if (lampResponse.status === 201) {
        setDiagnosticSuccess(true);
        const failedTests = Object.keys(lampResponse.data).filter(
          (lampKey) => lampResponse.data[lampKey].test_result === "Fail"
        );

        if (failedTests.length > 0) {
          // Failure notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 1
              ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
              : 'Failed Diagnostic'
          });
          toast.update(toastId, {
            render: "Please Clean the Flow Cell",
            type: "error",
            isLoading: false,
            autoClose: 6000,
          });
        } else {
          // Success notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 0
              ? `Completed Successful Diagnostic (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
              : 'Completed Successful Diagnostic'
          });

          // Reset retry count on success
          retryCountRef.current['Diagnostic'] = 0;
          let latestDiagnostic = JSON.stringify({ dated: new Date() });
          localStorage.setItem("latestDiagnostic", latestDiagnostic);
          setLatestDiagnostic({ old: false, dated: new Date() + "" });
          toast.update(toastId, {
            render: "Diagnostics Ran Successfully",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        }
      }
    } catch (error) {
      retryCountRef.current['Diagnostic']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 1
          ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
          : 'Failed Diagnostic'
      });

      console.error("Error fetching data:", error);
      setLatestDiagnostic({ old: true, dated: "--/--/--" });
      setDiagnosticSuccess(false);
      setErrorModal({
        visible: true,
        title: 'Diagnostics Failed',
        message: retryCountRef.current['Diagnostic'] >= 2
          ? 'Please contact support to resolve the issue.'
          : 'Please Clean the Flow Cell',
        failedComponent: 'Diagnostic',
        retryCount: retryCountRef.current['Diagnostic']
      });
      toast.update(toastId, {
        render: "Error Running the Diagnostics",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  }, [selectedConnection]);

	const handleYesClick = () => {
		fetchData();
	};

	const fetchDevices = async () => {
		const user_connection = await jwtInterceoptor.get(
			`${process.env.REACT_APP_API_URL}/user/api/user-connection/`
		);
		return user_connection?.data?.results;
	};

	useEffect(() => {
		fetchDevices().then((data) => {
			if (data) {
				const currentUrl = window.location.href;
				if (currentUrl.includes('demo')) {
					const filteredData = data.filter(
						(device: { machine_name: string; }) => device.machine_name.toLowerCase() === 'dummy'
					);
					setConnection(filteredData);
				}else{
					setConnection(data);
				}
			}
		});
		//cleanup
		return () => {
			setConnection([]);
		};
	}, []);

	const checkDeviceStatus = useCallback(async (id: number) => {

		let deviceStatus;
		let toastId;
		toastId = toast.loading("Checking Hardware Status");
		try {
			const user_connection = await jwtInterceoptor.get(
				`${process.env.REACT_APP_API_URL}/user/api/user-connection/${id}/`
			);
			let response = user_connection.data;
			deviceStatus = response.status_active;
			setDeviceStatus(deviceStatus);
			toast.update(toastId, {
				render: `${response.machine_name} Connected`,
				type: toast.TYPE.SUCCESS,
				isLoading: false,
				autoClose: 2000,
			});
		} catch (error) {

			toast.update(toastId, {
				render: "Failed to check hardware status",
				type: toast.TYPE.ERROR,
				isLoading: false,
				autoClose: 2000,
			});
		}
	}, [setDeviceStatus]);

	const handleConnections = useCallback(() => {
		if (!selectedConnection) {
			toast.error("Select your Hardware");
			return;
		}
		checkDeviceStatus(selectedConnection.id);
	}, [selectedConnection, checkDeviceStatus]);

	useEffect(() => {
		if (selectedConnection) {
			handleConnections();
			localStorage.setItem(
				"selectedHardware",
				JSON.stringify({...selectedConnection, dated: new Date()})
			);
		}
	}, [handleConnections, selectedConnection]);

  const handleUnitCalibration = useCallback(async () => {
    var toastId = toast.loading("Calibrating...");
    try {
      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Calibration'] > 0
          ? `Calibration started (Attempt ${retryCountRef.current['Calibration'] + 1})`
          : 'Calibration started'
      });

      const calibration = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=calibrate`
      );

      if (calibration?.status === 201) {
        setCalibrationSuccess(true);
        // Success notification with retry count
        await notifyAutomationServer({
          machine_name: selectedConnection?.machine_name,
          device_type: 'System Control Center',
          command: retryCountRef.current['Calibration'] > 0
            ? `Completed Successful Calibration (Attempt ${retryCountRef.current['Calibration'] + 1})`
            : 'Completed Successful Calibration'
        });

        // Reset retry count on success
        retryCountRef.current['Calibration'] = 0;

        toast.update(toastId, {
          render: "Successfully Calibrated",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
        localStorage.setItem("latestUnitCalibration", JSON.stringify({ dated: new Date() }));
        setLatestUnitCalibration({
          dated: new Date() + "",
          old: isMoreThanOneDay(new Date()),
        });
      }
    } catch (error) {
      retryCountRef.current['Calibration']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Calibration'] > 1
          ? `Failed Calibration (Attempt ${retryCountRef.current['Calibration']})`
          : 'Failed Calibration'
      });

      localStorage.removeItem("latestUnitCalibration");
      setLatestUnitCalibration({ old: true, dated: "--/--/--" });
      setCalibrationSuccess(false);
      setErrorModal({
        visible: true,
        title: 'Calibration Failed',
        message: retryCountRef.current['Calibration'] >= 2
          ? 'Multiple calibration attempts failed. Please contact support to resolve the issue.'
          : 'Calibration failed. Please ensure the system is properly connected and try again.',
        failedComponent: 'Calibration',
        retryCount: retryCountRef.current['Calibration']
      });

      toast.update(toastId, {
        render: "Error Calibrating Spectrometer",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  }, [selectedConnection]);

  const notifyStatus = async (isStarting: boolean) => {
    try {
      const payload = {
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: isStarting ? 'Automation Started' : 'Automation Stopped'
      };

      const response = await axios.post(
        `${process.env.REACT_APP_POWER_AUTOMATION_API_URL}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(response, "response");
    } catch (error) {
      console.log(error, "error");
    }
  }

  const openScheduleAutomationModal = async (e: any) => {
    if (e?.target?.checked) {
      setShowModal(true);
    } else {
      toast.info("Automation disabled.");
      notifyStatus(false);
      setAutomationInterval(null);
      setAutomationTime(null);
      setNextScheduledTime(null);
      setLastCompletedWorkflow(null);
      setIsExecutingCommands(false);
    }
  };

  const handleSave = (time: string, interval: string) => {
    console.log("Automation scheduled at:", time, "with interval:", interval);

    // Calculate next scheduled time for the next day
    const now = new Date();
    const [hours, minutes] = time.split(":").map(Number);
    const nextTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, hours, minutes, 59);
    setNextScheduledTime(nextTime.toLocaleString()); // Format as needed
    setAutomationTime(time);
    setAutomationInterval(interval === "daily" ? 86400000 : 10000); // 86400000 ms for daily, 10000 ms for testing
    notifyStatus(true);
    setShowModal(false);
  };

	// First, define requestWakeLock and releaseWakeLock with useCallback
	const requestWakeLock = useCallback(async () => {
		try {
			const wakeLock = await navigator.wakeLock.request('screen');
			setWakeLock(wakeLock);
			console.log('Wake Lock is active');
		} catch (err: any) {
			console.error(`${err.name}, ${err.message}`);
		}
	}, []);  // Empty dependency array since it doesn't depend on any state/props

	const releaseWakeLock = useCallback(async () => {
		if (wakeLock) {
			await wakeLock.release();
			setWakeLock(null);
			console.log('Wake Lock has been released');
		}
	}, [wakeLock]);  // Only depends on wakeLock state

	// Then define handleVisibilityChange which depends on the above functions
	const handleVisibilityChange = useCallback(async () => {
		if (document.visibilityState === 'visible') {
			await requestWakeLock();
		} else {
			await releaseWakeLock();
		}
	}, [requestWakeLock, releaseWakeLock]);

	// Finally define triggerCommands which depends on multiple things
	const triggerCommands = useCallback(async () => {
		console.log("Triggering commands");
		// var toastId = toast.loading("diagnosting...");
		setIsExecutingCommands(true);
		await requestWakeLock();
		try {

			await fetchDataCopy();
			await handleUnitCalibration();
      await handleScanHardwareCopy();
			const now = new Date();
			setLastCompletedWorkflow(now.toLocaleString());

			// Schedule the next execution
			if (automationInterval) {
				setTimeout(triggerCommands, automationInterval);
			}
		} catch (error: any) {
			setAutomationTime(null);
			setAutomationInterval(null);
			console.error("Error triggering commands:", error);
		} finally {
			// Only release wake lock if nextScheduledTime is null
			if (!nextScheduledTime) {
				await releaseWakeLock();
			}
			setIsExecutingCommands(false);
		}
	}, [
		nextScheduledTime,
		requestWakeLock,
		releaseWakeLock,
		automationInterval,
		fetchDataCopy,
		handleUnitCalibration,
		handleScanHardwareCopy
	]);

	// Effect to handle automation
	useEffect(() => {
		let intervalId: NodeJS.Timeout | null = null;

		const checkScheduledTime = () => {
			const now = new Date();
			const scheduledTime = new Date();
			if (automationTime) {
				const [hours, minutes] = automationTime.split(":").map(Number);
				scheduledTime.setHours(hours, minutes, 59); // Set seconds to 0
			}

			// Check if the current time matches the scheduled time (hours, minutes, and seconds)
			if (now.getHours() === scheduledTime.getHours() &&
				now.getMinutes() === scheduledTime.getMinutes() &&
				now.getSeconds() === 59 && // Check for seconds
				!isExecutingCommands) {
				triggerCommands();
			}
		};

		if (automationInterval && automationTime) {
			checkScheduledTime(); // Initial check

			// Set a timeout to check again after the specified interval
			intervalId = setInterval(checkScheduledTime, 1000);
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [automationTime, automationInterval, isExecutingCommands, triggerCommands]);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [handleVisibilityChange]);

  useEffect(() => {
    console.log('diagnosticSuccess updated:', diagnosticSuccess);
    localStorage.setItem('systemHealth',
      (diagnosticSuccess && calibrationSuccess && solventSuccess) ? 'true' : 'false'
    );
  }, [diagnosticSuccess, calibrationSuccess, solventSuccess]);

	return (

		<CContainer>
			<h1
				style={{
					fontWeight: 700,
					fontSize: "25px",
					lineHeight: "43.57px",
					color: "#000000",
					marginBottom: "20px",
					textAlign: "center",
					fontFamily: "Expansiva, sans-serif",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				System Control Center
			</h1>

      <div className="mb-3">
				<div style={{color: '#1488f5', fontSize: '14px', fontWeight: '600', fontFamily: 'Inter'}}>
          <CFormSwitch
            label="Manual / Automated"
						onClick={openScheduleAutomationModal}
            className="d-flex justify-content-center align-items-center gap-2"
            style={{
							backgroundColor: '#1488f5',
							// backgroundColor: '#4e64f5',
						}}
          />
				</div>
			</div>

      <ScheduleAutomationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleSave}
      />
      <div
        className="d-flex justify-content-center align-items-center gap-5 p-3"

      >
        <div>
          Next Scheduled Time :
          <span
            style={{
              fontSize: "14px",
              marginLeft: "5px",
              fontWeight: 700,
              lineHeight: "43.57px",
              color: "#000000",
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "Expansiva, sans-serif",
              display: "inline",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {nextScheduledTime || "Not scheduled"}
          </span>
        </div>
        <div>
          Last Completed Workflow :
          <span
            style={{
              marginLeft: "5px",
              fontWeight: 700,
              lineHeight: "43.57px",
              color: "#000000",
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "Expansiva, sans-serif",
              display: "inline",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {lastCompletedWorkflow || "Never"}
          </span>
        </div>
      </div>

			<CContainer className="container">
				<CRow>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
						<SelectYourHardware
							selectedConnection={selectedConnection}
							setSelectedConnection={setSelectedConnection}
							connections={connections}
							deviceStatus={deviceStatus}
							handleConnections={handleConnections} />
					</CCol>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
						<Diagnostic latestDiagnostic={latestDiagnostic} handleYesClick={handleYesClick} />
					</CCol>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
						<Calibration latestUnitCalibration={latestUnitCalibration} handleUnitCalibration={handleUnitCalibration} />
					</CCol>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
					<SolventReference latestSolventReference={latestSolventReference} handleScanHardware={handleScanHardware} />
					</CCol>
				</CRow>
			</CContainer>
			<CRow>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CButton
						variant="ghost"
						type="submit"
						style={{
							backgroundColor:
								!latestUnitCalibration?.old &&
								!latestDiagnostic?.old &&
								!latestSolventReference?.old
									? "#3AC90A"
									: "#D8D8D8",
							color: "#000000",
							fontWeight: "600",
							height: "45px",
							fontSize: "14px",
							fontFamily: "Inter",
							width: "30%",
							marginTop: "20px",
						}}
						onClick={() => navigate("/ExtractoPredictionDashboard")}
					>
						Start Measuring Sample
					</CButton>
				</div>
			</CRow>
      <ErrorModal />
		</CContainer>
	);
};

export default SystemControlCenter;
